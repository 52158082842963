import DOMPurify from 'dompurify';
import { LitElement } from 'lit';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';

const loadStyle = (style, prefix = Date.now().toString()) => {
    if (!style || !('cssText' in style) || style.cssText.length < 1) {
        return;
    }
    if (typeof prefix !== 'string') {
        console.debug('ensure to pass a string as prefix to avoid duplicates');
    }

    const id = `lit-element-css-${prefix}`;

    if (document.getElementById(id)) {
        return;
    }

    const s = document.createElement('style');
    s.id = id;
    s.textContent = style.cssText;
    document.head.appendChild(s);
};

class BaseElement extends LitElement {
    static localStrings = [];

    constructor() {
        super();
        this.slots = {};

        /* eslint-disable wc/no-constructor-attributes */
        this.querySelectorAll('[slot]').forEach((slot) => {
            this.slots[slot.getAttribute('slot')] = slot.innerHTML;
            slot.parentNode.removeChild(slot);
        });
        /* eslint-enable wc/no-constructor-attributes */
    }

    createRenderRoot() {
        return this;
    }

    loadStyle(style, prefix = Date.now()) {
        loadStyle(style, `${this.constructor.name}-${prefix}`);
        if (typeof prefix !== 'string') {
            console.debug('ensure to pass a string as prefix to avoid duplicates');
        }
    }

    updated() {
        if (super.updated) super.updated();
        if (this.renderRoot.querySelector('input, form, select, textarea')) {
            document.dispatchEvent(
                new CustomEvent('initCustomInputs', {
                    detail: { parent: this.renderRoot },
                })
            );
            import('../util/validator').then(({ default: validator }) => {
                validator.init();
                validator.initializeEvents(this.renderRoot, true);
                validator.initForm(this.renderRoot);
            });
        }
    }
}

const toBoolean = (value) =>
    (value && [true, 1].includes(value)) || ['true', '1'].includes(value.toLowerCase());

const getTextProperties = (localStrings) => {
    const props = {};
    localStrings.forEach((str) => {
        props[camelCase(`text ${str}`)] = {
            type: String,
            attribute: `text-${kebabCase(str)}`,
        };
    });

    return props;
};

const sanitizeHTML = (text, opts = {}) => DOMPurify.sanitize(text, opts);

export default BaseElement;

export { BaseElement, toBoolean, getTextProperties, loadStyle, sanitizeHTML };
