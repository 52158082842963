export default (providedUrl) => {
    let query;
    if (providedUrl) {
        const qPos = providedUrl.indexOf('?');
        if (qPos >= 0) {
            query = providedUrl.substring(qPos + 1);
        } else if (providedUrl.indexOf('#') === -1) {
            query = providedUrl;
        }
    } else {
        query = window.location.search.substring(1);
    }

    const returnObj = {};
    if (!query) {
        return returnObj;
    }

    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split('=');
        if (pair[0]) {
            const key = pair[0];
            const value = pair[1] || null;
            returnObj[key] = value;
        }
    }
    return returnObj;
};
