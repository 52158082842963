import debounce from 'lodash/debounce';

// helper methods
import {
    bindEvents,
    mq,
    multiAddEventListener,
    multiClassList,
    multiRemoveEventListener,
} from '../../../assets/helpers';
import { lockBodyScroll, unlockBodyScroll } from '../../../assets/helpers/lock-body';

// controllers
import ControllerRaf from '../../../assets/controllers/controller-raf/script';

export const selector = 'navigation-primary';

const prefersReducedMotion = matchMedia('(prefers-reduced-motion)')?.matches;

const closeCollapse = (collapse) => window.bootstrap.Collapse.getOrCreateInstance(collapse).hide();
const closeAllCollapses = (sheet) => {
    sheet?.querySelectorAll('.collapse').forEach((collapse) => closeCollapse(collapse));
};
const wrapSubMenuHTML = (html) =>
    `<div class="navigation-primary__expanded-secondary-menu"><ul class="navigation-primary__expanded-section--content-wrapper">${html}</ul></div>`;

const isExpandable = (treeItem) => treeItem.hasAttribute('aria-expanded');

const isExpanded = (treeItem) => treeItem.getAttribute('aria-expanded') === 'true';

const setFocusToTreeItem = (treeItem) => {
    if (!treeItem) {
        return;
    }

    treeItem.focus();
};

const collapseTreeItem = (treeItem) => {
    if (treeItem.hasAttribute('data-bs-toggle')) {
        const groupNode = document.getElementById(treeItem.getAttribute('aria-owns'));
        if (groupNode) {
            if (groupNode.classList.contains('collapse')) {
                window.bootstrap.Collapse.getOrCreateInstance(groupNode).hide();
            } else if (groupNode.classList.contains('offcanvas')) {
                window.bootstrap.Offcanvas.getOrCreateInstance(groupNode).hide();
            }
        }
    }
};

const expandTreeItem = (treeItem) => {
    if (treeItem.getAttribute('aria-owns')) {
        const groupNode = document.getElementById(treeItem.getAttribute('aria-owns'));
        if (groupNode) {
            if (groupNode.classList.contains('collapse')) {
                window.bootstrap.Collapse.getOrCreateInstance(groupNode).show();
            } else if (groupNode.classList.contains('offcanvas')) {
                window.bootstrap.Offcanvas.getOrCreateInstance(groupNode).show();
            }
        }
    }
};

const setFocusToNextTreeItem = (tgt, allowOpen) => {
    if (allowOpen && isExpandable(tgt) && isExpanded(tgt)) {
        const groupNode = document.getElementById(tgt.getAttribute('aria-owns'));
        if (groupNode) {
            setFocusToTreeItem(groupNode.querySelector('[role=treeitem]'));
        }
    } else {
        setFocusToTreeItem(tgt.parentElement?.nextElementSibling.querySelector('[role=treeitem]'));
    }
};

const isInSubtree = (treeItem) => treeItem?.closest('ul')?.getAttribute('role') === 'group';
export default class NavigationPrimary {
    constructor(section, options) {
        this.el = {
            section,
        };

        this.grabElements();

        // know when nav is pinned
        // thanks: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
            { threshold: [1] }
        );

        observer.observe(this.el.section);

        // default parameters to be merged with provided options
        this.defaults = {
            noFlyGutter: 300, // additional distance surrounding no fly regions where nav will not reveal
            debounce: 10,
        };

        // merge default options with param options
        this.options = { ...this.defaults, ...options };

        // class variable groups

        this.bounds = {
            hero: null,
            noFlys: [],
        };

        this.coords = {
            toggleScrollY: 0,
            topNavHeight: 0,
        };

        this.flags = {
            isExpanded: false,
            isExpandedIn: false,
            isMobileOpen: false,
            isScrollConcat: false,
            isSticky: false,
            isSubmenuOpen: false,
        };

        this.states = {};

        this.debounced = {
            resize: debounce(this.checkResize.bind(this), 250, {
                leading: false,
                trailing: true,
            }),
        };

        this.timeouts = {
            remove: null,
            sticky: null,
        };

        // bind events to class scope
        this.events = bindEvents(this);
        this.init();
    }

    // initializers

    init() {
        this.initializeMobileMenu();
        this.initializeDesktopMenu();
        this.grabElements();
        this.addEventListeners();
    }

    grabElements() {
        const { section } = this.el;
        this.el = {
            ...this.el,
            drawerTop: document.querySelector('.drawer-top'),
            nav: document.querySelector('.navigation-primary'),
            utilityBar: document.querySelector('.utility-bar'),
            utilityNav: document.querySelector('.offcanvas--utility-nav'),

            // ctas
            backCtaWrapper: section.querySelector(`.${selector}__expanded-bar-back-cta-wrapper`),
            backCta: section.querySelector(`.${selector}__expanded-bar-back-cta`),
            closeCta: section.querySelector(`.${selector}__expanded-bar-close-cta`),
            searchCta: section.querySelector(`.${selector}__expanded-bar-search-cta`),

            // nav items
            navItems: section.querySelectorAll(`.${selector}__nav-item`),
            navItemsLinks: section.querySelectorAll(`.${selector}__nav-item a`),
            icons: section.querySelectorAll(`.${selector}__icon`),
            iconLinks: section.querySelectorAll(`.${selector}__icon button`),
            sectionTitle: section.querySelector(`.${selector}__expanded-bar-submenu-title`),
            treeItems: section.querySelectorAll('[role="treeitem"]'),

            // any sections with expanded states
            expandedSections: section.querySelectorAll(`.${selector}__expanded-section`),

            // expanded links
            expandedNavItemsContainer: section.querySelector(`.${selector}__expanded-nav-items`),
            expandedNavItems: section.querySelectorAll(
                `.${selector}__expanded-nav-items .${selector}__nav-item`
            ),
            expandedNavItemsLinks: section.querySelectorAll(
                `.${selector}__expanded-nav-items .${selector}__expanded-nav-item a`
            ),
            expandedSectionLinks: section.querySelectorAll(
                `.${selector}__expanded-section li > button`
            ),
            overflowLinks: section.querySelectorAll(
                `.${selector}__expanded-section .overflow--item-link`
            ),

            // expanded mobile hamburger menu
            expandedMobileSection: section.querySelector(`.${selector}__expanded-section--mobile`),
            expandedMobileFolderLinks: section.querySelectorAll(
                `.${selector}__expanded-section--mobile .is-menu-mobile-only:not([data-section="shop"]) button`
            ),

            // primary menus
            primarySectionsWrapper: section.querySelector(
                `.${selector}__expanded-primary-menus-wrapper`
            ),

            // mainNavOffcanvases
            mainNavOffcanvases: section.querySelectorAll('.offcanvas--main-nav'),

            // submenu
            submenuWrapper: section.querySelector(`.${selector}__expanded-submenu-wrapper`),
            submenu: null,

            // tracking state
            lastSection: [],
            lastTitle: [],
        };
    }

    initializeDesktopMenu() {
        // Move story tile to the correct spot
        const storyTile = this.el.section.querySelector('#stories-menu-tile .stories-menu-tile');
        if (storyTile) {
            const storiesLevelOne = this.el.section.querySelector(
                '#offcanvas-nav-stories .level-one'
            );
            if (storiesLevelOne) {
                storiesLevelOne.prepend(storyTile);
            }
        }

        this.el.mainNavOffcanvases?.forEach((menu) => {
            menu.querySelectorAll('.offcanvas-body button[aria-owns]')?.forEach((button) => {
                const ariaOwns = button.getAttribute('aria-owns');
                if (ariaOwns) {
                    const submenu = menu.querySelector(`#${ariaOwns}`);

                    if (submenu && submenu.querySelector('a, button')) {
                        const { id } = submenu;
                        submenu.classList.add('collapse');
                        submenu.classList.remove('d-none');

                        submenu.addEventListener('show.bs.collapse', () => {
                            button.classList.add('active');
                            button.setAttribute('aria-expanded', 'true');
                        });

                        // Event is fired when a collapse element has been made visible.
                        submenu.addEventListener('shown.bs.collapse', () => {
                            const offcanvasBody = menu.querySelector('.offcanvas-body');

                            if (offcanvasBody.scrollTop !== 0) {
                                offcanvasBody.scrollTo({ top: 0, behavior: 'smooth' });
                            }
                        });

                        submenu.addEventListener('hide.bs.collapse', () => {
                            button.classList.remove('active');
                            button.setAttribute('aria-expanded', 'false');
                        });

                        new window.bootstrap.Collapse(submenu, {
                            parent: submenu.closest('ul').closest('div'),
                            toggle: false,
                        });

                        button.setAttribute('data-bs-toggle', 'collapse');
                        button.setAttribute('data-bs-target', `#${id}`);
                        button.setAttribute('aria-expanded', 'false');
                        button.setAttribute('aria-controls', id);
                    } else {
                        button.closest('li').remove();
                    }
                }
            });

            // Hide level three and four menus on level 2 closing
            menu.querySelectorAll('.offcanvas-body .level-two ul[id*="menu"]')?.forEach(
                (levelTwoMenu) => {
                    levelTwoMenu.addEventListener('hide.bs.collapse', () => {
                        menu.querySelectorAll(
                            '.level-three ul[id*="menu"], .level-four ul[id*="menu"]'
                        ).forEach((subMenu) => closeCollapse(subMenu));
                    });
                }
            );

            // Hide level four menus on level 3 closing
            menu.querySelectorAll('.offcanvas-body .level-three ul[id*="menu"]')?.forEach(
                (levelThreeMenu) => {
                    levelThreeMenu.addEventListener('hide.bs.collapse', () => {
                        menu.querySelectorAll('.level-four ul[id*="menu"]').forEach((subMenu) =>
                            closeCollapse(subMenu)
                        );
                    });
                }
            );
        });
    }

    initializeMobileMenu() {
        if (!this.el.expandedMobileSection) {
            return;
        }

        const desktopShopMenu = [...this.el.mainNavOffcanvases].find(
            (menu) => menu.id === 'offcanvas-nav-shop'
        );

        const id = 'shop';

        const mobileMenu = this.el.expandedMobileSection.querySelector(`[data-section="${id}"]`);

        const shopMenuItems = desktopShopMenu
            .querySelector('.offcanvas-body')
            .querySelector('.level-one ul');

        const newList = document.createElement('ul');
        newList.innerHTML = shopMenuItems.innerHTML;

        newList.querySelectorAll('a, .shop-menu-additional-links')?.forEach((elem) => {
            elem.remove();
        });

        newList.querySelectorAll('button[aria-owns]')?.forEach((button) => {
            const submenu = desktopShopMenu.querySelector(
                `ul[id="${button.getAttribute('aria-owns')}"]`
            );

            if (!submenu || !submenu.querySelector('a, button')) {
                button.closest('li').remove();
            }
        });

        mobileMenu.innerHTML = newList.outerHTML;

        if (
            this.el.utilityNav.classList.contains('show') ||
            this.el.utilityNav.classList.contains('showing')
        ) {
            this.el.utilityBar.classList.add('is-hidden');
        }
    }

    // event listeners

    addEventListeners() {
        this.el.utilityNav.addEventListener('show.bs.offcanvas', () => {
            this.flags.isExpandedIn = true;
            this.el.utilityBar.classList.add('is-hidden');
        });

        this.el.utilityNav.addEventListener('hide.bs.offcanvas', () => {
            this.flags.isExpandedIn = false;
            this.el.utilityBar.classList.remove('is-hidden', 'trigger-hamburger-mobile');

            this.closeMobileMenu();
        });

        multiAddEventListener(
            this.el.expandedMobileFolderLinks,
            'click',
            this.events._onMobileMenuFolderSectionLinkClick
        );
        multiAddEventListener(
            this.el.expandedSectionLinks,
            'click',
            this.events._onSectionLinkClick
        );

        multiAddEventListener(this.el.treeItems, 'keydown', this.events._onTreeItemKeydown);
        multiAddEventListener(this.el.navItems, 'keydown', this.events._onTreeItemKeydown);

        multiAddEventListener(this.el.navItemsLinks, 'click', this.events._onNavItemClick);
        multiAddEventListener(
            this.el.expandedNavItemsLinks,
            'click',
            this.events._onExpandedNavItemClick
        );

        if (this.el.backCta) {
            this.el.backCta.addEventListener('click', this.events._onBackCtaClick);
        }
        if (this.el.closeCta) {
            this.el.closeCta.addEventListener('click', this.events._onCloseCtaClick);
        }
        if (this.el.searchCta) {
            this.el.searchCta.addEventListener('click', this.events._onSearchCtaClick);
        }

        const $hamburger = this.el.section.querySelector(`.${selector}__icon--hamburger button`);

        $hamburger.addEventListener('click', this.events._onHamburgerClick);

        $(document).on('store:selected', this.events._onCloseCtaClick);

        window.addEventListener(
            'resize',
            debounce(this.events._onWindowResize.bind(this), this.options.debounce, {
                leading: false,
                trailing: true,
            })
        );

        document.addEventListener('keyup', (e) => {
            if (!this.flags.isExpandedIn) {
                return;
            }

            if (e.defaultPrevented) {
                return;
            }
            const key = e.key || e.keyCode;
            if (['Escape', 'Esc', 27].includes(key)) {
                window.bootstrap.Offcanvas.getOrCreateInstance(this.el.utilityNav).hide();
            }
        });

        this.el.section.querySelectorAll('[data-bs-toggle="offcanvas"]').forEach((link) => {
            const sheet = document.querySelector(link.dataset.bsTarget);
            if (sheet) {
                sheet.addEventListener('show.bs.offcanvas', () => {
                    link.classList.add('active');
                    document.body.classList.add('nav-open');
                    link.setAttribute('aria-expanded', 'true');

                    // in case of search button click, add close offcanvas rules
                    document
                        .querySelector('[data-section-id="search"]')
                        .setAttribute('data-bs-dismiss', 'offcanvas');
                    document
                        .querySelector('[data-section-id="search"]')
                        .setAttribute('data-bs-target', '#offcanvas-nav-shop');
                });
                sheet.addEventListener('hide.bs.offcanvas', () => {
                    link.classList.remove('active');
                    closeAllCollapses(sheet);
                    document.body.classList.remove('nav-open');
                    link.setAttribute('aria-expanded', 'false');

                    // remove search button rules
                    document
                        .querySelector('[data-section-id="search"]')
                        .removeAttribute('data-bs-dismiss');
                    document
                        .querySelector('[data-section-id="search"]')
                        .removeAttribute('data-bs-target');
                });
            }
        });

        // close hamburger menu if country selector menu is opened
        this.el.expandedMobileSection.addEventListener('click', (event) => {
            const $countrySelector = event.target.closest('.js-locale-selector-button');
            if ($countrySelector) {
                window.bootstrap.Offcanvas.getOrCreateInstance(this.el.utilityNav).hide();
            }
        });

        this.checkResize();
    }

    removeEventListeners() {
        multiRemoveEventListener(
            this.el.expandedMobileFolderLinks,
            'click',
            this.events._onMobileMenuFolderSectionLinkClick
        );
        multiRemoveEventListener(
            this.el.expandedNavItemsLinks,
            'click',
            this.events._onExpandedNavItemClick
        );
        multiRemoveEventListener(
            this.el.expandedSectionLinks,
            'click',
            this.events._onSectionLinkClick
        );
        multiRemoveEventListener(this.el.navItemsLinks, 'click', this.events._onNavItemClick);

        multiRemoveEventListener(this.el.treeItems, 'keydown', this.events._onTreeItemKeydown);
        multiRemoveEventListener(this.el.navItems, 'keydown', this.events._onTreeItemKeydown);

        if (this.el.backCta) {
            this.el.backCta.removeEventListener('click', this.events._onBackCtaClick);
        }
        if (this.el.closeCta) {
            this.el.closeCta.removeEventListener('click', this.events._onCloseCtaClick);
        }
        if (this.el.searchCta) {
            this.el.searchCta.removeEventListener('click', this.events._onSearchCtaClick);
        }

        window.removeEventListener(
            'resize',
            debounce(this.events._onWindowResize.bind(this), this.options.debounce, {
                leading: false,
                trailing: true,
            })
        );
    }

    // listener methods

    _onNavItemClick(e) {
        const $target = e.currentTarget;
        if ($target.getAttribute('data-section')) {
            e.preventDefault();
            this.el.nav.classList.add('global-nav-active');
            this.activateMenuItem($target);
        }
    }

    // https://w3c.github.io/aria-practices/examples/treeview/treeview-navigation.html
    _onTreeItemKeydown(event) {
        const tgt = event.currentTarget;
        let flag = false;
        const { key } = event;

        function isPrintableCharacter(str) {
            return str.length === 1 && str.match(/\S/);
        }

        if (event.altKey || event.ctrlKey || event.metaKey) {
            return;
        }

        if (event.shift) {
            if (event.keyCode === this.keyCode.SPACE || event.keyCode === this.keyCode.RETURN) {
                event.stopPropagation();
            } else if (isPrintableCharacter(key)) {
                if (key !== '*') {
                    this.setFocusByFirstCharacter(tgt, key);
                }
            }
        } else {
            switch (key) {
                // NOTE: Return key is supported through the click event
                case ' ':
                    this.updateContent(tgt.href, tgt.textContent.trim());
                    flag = true;
                    break;

                case 'Up':
                case 'ArrowUp':
                    this.setFocusToPreviousTreeItem(tgt);
                    flag = true;
                    break;

                case 'Down':
                case 'ArrowDown':
                    setFocusToNextTreeItem(tgt);
                    flag = true;
                    break;

                case 'Right':
                case 'ArrowRight':
                    if (isExpandable(tgt)) {
                        if (isExpanded(tgt)) {
                            setFocusToNextTreeItem(tgt, true);
                        } else {
                            expandTreeItem(tgt);
                        }
                    }
                    flag = true;
                    break;

                case 'Left':
                case 'ArrowLeft':
                    if (isExpandable(tgt) && isExpanded(tgt)) {
                        collapseTreeItem(tgt);
                        flag = true;
                    } else if (isInSubtree(tgt)) {
                        this.setFocusToParentTreeItem(tgt);
                        flag = true;
                    }
                    break;

                // case 'Home':
                //     setFocusToTreeItem(this.treeItems[0]);
                //     flag = true;
                //     break;

                // case 'End':
                //     var visibleTreeItems = this.getVisibleTreeItems();
                //     setFocusToTreeItem(visibleTreeItems[visibleTreeItems.length - 1]);
                //     flag = true;
                //     break;

                default:
                    // if (isPrintableCharacter(key)) {
                    //     if (key !== '*') {
                    //         this.setFocusByFirstCharacter(tgt, key);
                    //     }
                    // }
                    break;
            }
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    getVisibleTreeItems() {
        const items = [];
        for (let i = 0; i < this.el.treeItems.length; i += 1) {
            const ti = this.el.treeItems[i];
            if (this.isTreeItemVisible(ti)) {
                items.push(ti);
            }
        }
        return items;
    }

    isTreeItemVisible(treeItem) {
        const flag = true;
        if (isInSubtree(treeItem)) {
            const parentTreeItem = this.getParentTreeItem(treeItem);
            if (!parentTreeItem || parentTreeItem.getAttribute('aria-expanded') === 'false') {
                return false;
            }
        }
        return flag;
    }

    getParentTreeItem(treeItem) {
        if (treeItem.closest('.level-one')) {
            const menuID = treeItem.closest('.offcanvas').id;
            return this.el.section.querySelector(`button[aria-owns='${menuID}']`);
        }

        const group = treeItem.closest('ul');
        if (group.getAttribute('role') !== 'group') {
            return false;
        }
        return treeItem.closest('.offcanvas')?.querySelector(`button[aria-owns="${group.id}"]`);
    }

    setFocusToPreviousTreeItem(treeItem) {
        const previousInList =
            treeItem.parentElement?.previousElementSibling?.querySelector('[role=treeitem]');
        const currentGroup = treeItem.closest('ul');
        const currentGroupParent = treeItem
            .closest('.offcanvas')
            ?.querySelector(`button[aria-owns="${currentGroup?.id}"]`);
        if (
            !previousInList &&
            currentGroup &&
            currentGroupParent &&
            currentGroup.getAttribute('role') === 'group'
        ) {
            this.setFocusToParentTreeItem(treeItem);
        } else if (previousInList) {
            setFocusToTreeItem(previousInList);
        } else if (treeItem.closest('.level-one')) {
            this.setFocusToParentTreeItem(treeItem);
        }
    }

    setFocusToParentTreeItem(treeItem) {
        if (isInSubtree(treeItem)) {
            const currentGroup = treeItem.closest('ul');
            const currentGroupParent = treeItem
                .closest('.offcanvas')
                ?.querySelector(`button[aria-owns="${currentGroup?.id}"]`);
            if (
                currentGroup &&
                currentGroupParent &&
                currentGroup.getAttribute('role') === 'group'
            ) {
                setFocusToTreeItem(currentGroupParent);
            } else if (treeItem.closest('.level-one')) {
                const menuID = treeItem.closest('.offcanvas').id;
                setFocusToTreeItem(this.el.section.querySelector(`button[aria-owns='${menuID}']`));
            }
        }
    }

    setFocusByFirstCharacter(treeItem, character) {
        let start;
        let i;
        let ti;
        let index = -1;
        const visibleTreeItems = this.getVisibleTreeItems();
        const char = character.toLowerCase();

        // Get start index for search based on position of treeItem
        start = visibleTreeItems.indexOf(treeItem) + 1;
        if (start >= visibleTreeItems.length) {
            start = 0;
        }

        // Check remaining items in the tree
        for (i = start; i < visibleTreeItems.length; i += 1) {
            ti = visibleTreeItems[i];
            if (char === ti.textContent.trim()[0].toLowerCase()) {
                index = i;
                break;
            }
        }

        // If not found in remaining slots, check from beginning
        if (index === -1) {
            for (i = 0; i < start; i += 1) {
                ti = visibleTreeItems[i];
                if (char === ti.textContent.trim()[0].toLowerCase()) {
                    index = i;
                    break;
                }
            }
        }

        // If match was found...
        if (index > -1) {
            setFocusToTreeItem(visibleTreeItems[index]);
        }
    }

    _onExpandedNavItemClick(e) {
        const $target = e.currentTarget;
        this.activateMenuItem($target);
    }

    _onBackCtaClick() {
        // clear out current state
        this.el.lastTitle.pop();
        this.el.lastSection.pop();

        if (this.el.lastSection.length > 0) {
            this.showPreviousSubmenu();
        } else if (this.flags.isSubmenuOpen) {
            this.hideSubmenu();
        } else if (!mq('large')) {
            this.openMobileMenu();
        }
    }

    _onCloseCtaClick() {
        if (this.flags.isExpandedIn) {
            this.closeMobileMenu();
        }
    }

    _onSearchCtaClick() {
        ControllerRaf.one(() => {
            this.closeMenu();
        });
    }

    _onHamburgerClick() {
        if (!mq('large')) {
            this.el.utilityNav.classList.add('trigger-hamburger-mobile');
        }
    }

    _onSectionLinkClick(e) {
        const $target = e.currentTarget;
        const submenuID = $target.getAttribute('aria-owns');

        if (typeof submenuID !== 'undefined') {
            // Make sure Section Title exist before using them
            if (this.el.sectionTitle) {
                this.el.sectionTitle.innerHTML = $target.innerHTML;
                this.el.lastTitle.push($target.innerHTML?.trim());
            }
            this.el.lastSection.push(submenuID);
            this.showSubmenu(submenuID);
            this.el.section.classList.remove('is-scroll-down');
        }
    }

    _onMobileMenuFolderSectionLinkClick(e) {
        const $target = e.currentTarget;
        this.activateMenuItem($target);
    }

    _onWindowResize() {
        this.checkResize();
    }

    activateMenuItem($target) {
        this.el.expandedMobileSection.classList.remove('show');
        this.el.submenuWrapper.classList.add('show');
        this.el.backCtaWrapper.classList.add('show');

        const id = $target.dataset.section;
        const desktopMenu = [...this.el.mainNavOffcanvases].find(
            (menu) => menu.id === `offcanvas-nav-${id}`
        );

        const list = desktopMenu.querySelector('.offcanvas-body').querySelector('.level-one ul');

        const submenuHTML = wrapSubMenuHTML(list.innerHTML);
        const dummyElem = document.createElement('div');
        dummyElem.innerHTML = submenuHTML;

        // remove the Bootstrap components from desktop version
        dummyElem.querySelectorAll('button').forEach((btn) => {
            btn.removeAttribute('data-bs-toggle');
            btn.removeAttribute('data-bs-target');
            btn.removeAttribute('aria-expanded');
            btn.removeAttribute('aria-controls');
        });
        this.el.submenuWrapper.innerHTML = '';
        this.el.submenuWrapper.append(dummyElem.firstChild);
        this.el.submenu = this.el.submenuWrapper.firstChild;

        this.initNewSubmenuWrapper();
    }

    initNewSubmenuWrapper() {
        multiAddEventListener(
            this.el.submenuWrapper.querySelectorAll('button[aria-owns]'),
            'click',
            this.events._onSectionLinkClick
        );
        this.el.section.classList.add('is-submenu-expanded');
        this.el.expandedMobileSection.classList.remove('show');
        this.el.submenuWrapper.classList.remove('hide');
        this.el.submenuWrapper.classList.add('show');
        this.el.backCtaWrapper.classList.add('show');
        this.el.utilityNav?.querySelector('.header-store-location')?.classList.remove('show');
    }

    expandMenuSection($targetSection) {
        const $lastSection = this.el.section.querySelector(`.${selector}__expanded-section.active`);

        if (!$targetSection || $targetSection === $lastSection) {
            this.closeMenu(!!$targetSection);
        } else {
            const id = $targetSection.getAttribute('data-section');
            const $lastNavItem = this.el.section.querySelector(
                `.${selector}__nav-item.active, .${selector}__icon.active`
            );
            const $targetLink = this.el.section.querySelector(
                `.${selector}__nav-item a[data-section="${id}"], .${selector}__icon a[data-section="${id}"]`
            );
            let $targetExpandedNavItem;
            if (this.el.expandedNavItemsContainer) {
                const $targetExpandedNavItemLink = this.el.mainNavOffcanvases[0].querySelector(
                    `[data-section="${id}"]`
                );
                if ($targetExpandedNavItemLink) {
                    $targetExpandedNavItem = $targetExpandedNavItemLink.closest('li')
                        ? $targetExpandedNavItemLink.closest('li')
                        : $targetExpandedNavItemLink.closest('div');
                }
            }
            let delayIn = 0;

            // let everyone know we expanded

            this.flags.isExpanded = true;

            // remove any active states

            multiClassList(this.el.expandedNavItems, 'remove', 'active');

            // remove active state from old selected nav item

            if ($lastNavItem) {
                $lastNavItem.classList.remove('active');
            }

            if ($targetExpandedNavItem) {
                $targetExpandedNavItem.classList.add('active');
            }

            // depending on scroll position, follow necessary behavior

            const scrollY = this.el.section === window ? window.scrollY : this.el.section.scrollTop;
            const sectionOffsetTop = this.el.section.offsetTop;

            if (sectionOffsetTop > 0 && scrollY < sectionOffsetTop) {
                window.scrollTo({
                    top: sectionOffsetTop,
                    behavior: 'smooth',
                });
            }

            // animate out old section

            if ($lastSection) {
                this.closeLastSection($lastSection);
                delayIn += 60;
            }

            // animate nav item to active state

            if ($targetLink) {
                const $targetNavItem = $targetLink.closest('li')
                    ? $targetLink.closest('li')
                    : $targetLink.closest('div');
                $targetNavItem.classList.add('active');
            }

            // animate in new section
            $targetSection.classList.add('active');

            setTimeout(() => {
                ControllerRaf.one(() => {
                    $targetSection.classList.add('is-expanded');
                });
            }, delayIn);

            // add wrapper expanded state

            this.el.section.classList.add('is-expanded', `is-section-${id}`);
            this.el.section.classList.remove('is-collapsed');

            ControllerRaf.one(() => {
                this.flags.isExpandedIn = true;
                this.el.section.classList.add('is-expanded-in');
            });

            try {
                window.dispatchEvent(new CustomEvent('menuExpand', { target: this.el.section }));
            } catch (err) {
                const event = document.createEvent('CustomEvent');
                event.initCustomEvent('menuExpand', false, false, {
                    target: this.el.section,
                });
                window.dispatchEvent(event);
            }

            // take expanded measurements
            if (this.el.expandedNavItemsContainer) {
                this.coords.topNavHeight = this.el.expandedNavItemsContainer.clientHeight;
            }

            // lock body
            lockBodyScroll();

            this.el.utilityNav?.querySelector('.header-store-location')?.classList.add('show');
        }
    }

    closeLastSection($lastSection = null) {
        const $lastSectionValidated =
            $lastSection || this.el.section.querySelector(`.${selector}__expanded-section.active`);

        if (!$lastSectionValidated) {
            return;
        }
        const lastSectionId = $lastSection.getAttribute('data-section');

        if (this.flags.isSubmenuOpen) {
            this.hideSubmenu();
        }

        this.el.section.classList.remove(`is-section-${lastSectionId}`);

        $lastSectionValidated.classList.remove('is-expanded', 'active');

        setTimeout(() => {
            $lastSectionValidated.classList.remove('is-expanded');
        }, 100);
    }

    /**
     * Looks for the menu we are looking for and handles it
     * @param {string} submenuID - the menu ID we are looking for
     */
    showSubmenu(submenuID) {
        if (!submenuID) {
            return;
        }

        let submenu;

        if (submenuID.startsWith('offcanvas-nav-')) {
            // folder nav
            submenu = document.getElementById(submenuID).querySelector(`.level-one`);
        } else {
            // shop nav
            submenu = this.el.section.querySelector(`ul[id="${submenuID}"]`);
        }

        if (!submenu) {
            return;
        }

        let submenuHTML = submenu.innerHTML;

        if (!submenuHTML) {
            return;
        }

        if (!this.el.lastSection.includes(submenuID)) {
            this.el.lastSection.push(submenuID);
        }

        this.killRemoveTimeout();
        this.flags.isSubmenuOpen = true;

        submenuHTML = wrapSubMenuHTML(submenuHTML);

        const dummyElem = document.createElement('div');
        dummyElem.innerHTML = submenuHTML;

        // remove the Bootstrap components from desktop version
        dummyElem.querySelectorAll('button').forEach((btn) => {
            btn.removeAttribute('data-bs-toggle');
            btn.removeAttribute('data-bs-target');
            btn.removeAttribute('aria-expanded');
            btn.removeAttribute('aria-controls');
        });

        dummyElem.querySelectorAll('a').forEach((link) => {
            link.removeAttribute('role');
        });

        if (this.el.submenuWrapper.classList.contains('show') && !prefersReducedMotion) {
            this.el.submenuWrapper.addEventListener(
                'transitionend',
                () => {
                    this.replaceSubmenuWrapperContent(dummyElem);
                },
                { once: true }
            );

            this.el.submenuWrapper.classList.add('hide');
        } else {
            this.replaceSubmenuWrapperContent(dummyElem);
        }

        this.el.utilityNav?.querySelector('.header-store-location')?.classList.remove('show');
    }

    /**
     * Replaces mobile menu wrapper content and initializes functionality
     * @param {HTMLElement} parentElem - the desktop menu
     */
    replaceSubmenuWrapperContent(parentElem) {
        this.el.submenuWrapper.innerHTML = '';
        this.el.submenuWrapper.append(parentElem.firstChild);
        this.el.submenu = this.el.submenuWrapper.firstChild;
        this.initNewSubmenuWrapper();
    }

    showPreviousSubmenu() {
        if (this.el.lastTitle.length > 0) {
            this.el.sectionTitle.innerHTML = this.el.lastTitle[this.el.lastTitle.length - 1];
        } else {
            this.el.sectionTitle.innerHTML = '';
        }

        this.showSubmenu(this.el.lastSection[this.el.lastSection.length - 1]);
    }

    hideSubmenu(remove = true) {
        this.el.section.classList.remove('is-submenu-expanded');
        this.el.submenu?.classList.remove('is-expanded');

        if (remove && !this.timeouts.remove) {
            this.flags.isSubmenuOpen = false;
            this.timeouts.remove = setTimeout(() => {
                this.removeSubmenu();
                this.timeouts.remove = null;
                this.el.backCtaWrapper.classList.remove('show');
                this.el.expandedMobileSection.classList.add('show');
                this.el.submenuWrapper.classList.remove('show', 'is-expanded');
                this.el.sectionTitle.innerHTML = '';
                // clear out history
                this.el.lastSection = [];
                this.el.lastTitle = [];

                this.el.utilityNav?.querySelector('.header-store-location')?.classList.add('show');
            }, 300);
        }
    }

    killRemoveTimeout() {
        clearTimeout(this.timeouts.remove);
        this.timeouts.remove = null;
    }

    // remove sub-menu

    removeSubmenu() {
        if (!this.el.submenu) {
            return;
        }

        this.killRemoveTimeout();
        this.el.submenu.remove();
        this.el.submenu = null;
    }

    // close entire menu

    closeMenu(deselectNavItems = true, instant = false) {
        const $lastSection = this.el.section.querySelector(`.${selector}__expanded-section.active`);
        const delay = instant ? 0 : 300;

        this.flags.isExpanded = false;
        this.flags.isExpandedIn = false;

        this.el.section.classList.add('is-collapsed');
        this.el.section.classList.remove(
            'is-expanded-in',
            'is-mobile-index',
            'is-submenu-expanded'
        );
        this.el.nav.classList.remove('global-nav-active');
        this.el.submenuWrapper.classList.remove('show', 'is-expanded');
        this.el.expandedMobileSection.classList.add('show');
        this.el.backCtaWrapper.classList.remove('show');

        this.closeLastSection($lastSection);

        if (deselectNavItems) {
            multiClassList(this.el.navItems, 'remove', 'active');
            multiClassList(this.el.expandedNavItems, 'remove', 'active');
        }

        // clear out history
        this.el.lastSection = [];
        this.el.lastTitle = [];

        setTimeout(() => {
            this.el.section.classList.remove('is-expanded');
            // unlock body
            unlockBodyScroll();

            try {
                window.dispatchEvent(
                    new CustomEvent('menuCollapse', {
                        target: this.el.section,
                    })
                );
            } catch (err) {
                const event = document.createEvent('CustomEvent');
                event.initCustomEvent('menuCollapse', false, false, {
                    target: this.el.section,
                });
                window.dispatchEvent(event);
            }

            this.el.submenuWrapper.innerHTML = '';
            this.el.sectionTitle.innerHTML = '';

            window.scrollTo({
                top: this.coords.toggleScrollY,
            });
        }, delay);
    }

    // mobile menu

    openMobileMenu() {
        const $hamburgerSectionMobile = this.el.section.querySelector(
            `.${selector}__expanded-section[data-section="mobile"]`
        );

        this.el.lastTitle = [];
        if (this.el.sectionTitle) {
            this.el.sectionTitle.innerHTML = '';
        }

        ControllerRaf.one(() => {
            this.el.section.classList.add('is-mobile-index');
        });

        window.dispatchEvent(new Event(NavigationPrimary.NAV_ITEM_CLICK));

        this.expandMenuSection($hamburgerSectionMobile);
    }

    closeMobileMenu() {
        this.closeMenu();
    }

    returnOpenMenu(e) {
        const keycode = e.keyCode ? e.keyCode : e.which;
        const $target = e.currentTarget;
        // open on enter
        if (keycode === 13) {
            e.preventDefault();
            this.activateMenuItem($target);
            // move focus to the first link once it's opened
            setTimeout(() => {
                let index = 0;
                $('.overflow--item-link').each(function () {
                    if (index === 0) {
                        $(this).focus();
                    }
                    if ($(this).is(':visible')) {
                        $(this).attr('data-item-no', index);
                        index += 1;
                    }
                });
            }, 300);
        }
    }

    // browser state calculations

    checkResize() {
        if (mq('large')) {
            if (
                this.el.utilityNav.classList.contains('show') &&
                this.el.utilityNav.classList.contains('trigger-hamburger-mobile')
            ) {
                const utilNav = window.bootstrap.Offcanvas.getOrCreateInstance(this.el.utilityNav);
                utilNav.hide();
                this.el.utilityNav.classList.remove('trigger-hamburger-mobile');
            }
        } else {
            [...this.el.mainNavOffcanvases]?.forEach((can) => {
                window.bootstrap.Offcanvas.getOrCreateInstance(can).hide();
            });
        }
    }

    // destroy

    destroy() {
        this.closeMenu(true, true);
        this.removeEventListeners();
    }

    // const variables

    static get NAV_ITEM_CLICK() {
        return 'navItemClick';
    }
}
