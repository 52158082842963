import HeartBeat from './util/heartbeat';
import { loadAfterDOMContentLoaded } from './util/domHelpers';
import { setJSON } from './util/storage';
import { loadSprites } from './assets/components/sprite-loader';

/**
 * @description get session data
 */
const getState = (url) =>
    fetch(encodeURI(url))
        .then((resp) => {
            if (!resp.ok) {
                throw new Error('State error');
            }
            return resp;
        })
        .then((resp) => resp.json())
        .catch((error) => {
            console.warn(error);
            return false;
        });

/**
 * @description update main classes to reflect session state
 */
const setSessionMainClasses = (data) => {
    const main = document.querySelector('main[data-state-url]');
    const classes = data.mainClasses;

    [...classes].forEach((newClass) => {
        main.classList.add(newClass);
    });
};

/**
 * @description update main attributes to reflect session state
 */
const setSessionMainAttributes = (data) => {
    const main = document.querySelector('main[data-state-url]');
    const attributes = data.mainAttributes;

    attributes.forEach((attributeObject) => {
        main.dataset[attributeObject.name] = attributeObject.value;
    });
};

/**
 * @description update cart icon to reflect session state
 */
const setSessionCartState = (data) => {
    const cartIcons = document.body.querySelectorAll('.navigation-primary__icon--cart_count');
    if (cartIcons.length && data.productQuantityTotal > 0) {
        cartIcons.forEach((cartIcon) => {
            // eslint-disable-next-line no-param-reassign
            cartIcon.innerHTML = data.productQuantityTotal;
        });
    }
};

/**
 * @description updates customer number and session ID
 */
const setSessionCustomerAttributes = (data) => {
    const main = document.querySelector('main[data-state-url]');

    main.dataset.sessionId = data.sessionID;

    if (data.customerNo) {
        main.dataset.customerNo = data.customerNo;
    }
};

/**
 * @description init session state
 */
const initSessionState = () => {
    const elem = document.querySelector('main[data-state-url]');
    if (!elem) {
        return;
    }
    getState(elem.dataset.stateUrl).then((data) => {
        if (!data) {
            return;
        }
        setSessionMainClasses(data);
        setSessionMainAttributes(data);
        setSessionCartState(data);
        setSessionCustomerAttributes(data);

        if (data.modalUrl) {
            if (data.registeredTimedOut) {
                const modalsLocalStorage = [
                    {
                        url: data.modalUrl,
                        customEvent: 'initLoginLinkListeners',
                        showOnCheckout: false,
                        showOnLoad: true,
                    },
                ];

                setJSON('modals', modalsLocalStorage);
                window.location.reload();
            } else {
                document.dispatchEvent(
                    new CustomEvent('showRemoteModal', {
                        detail: {
                            url: data.modalUrl,
                            successEvent: 'initLoginLinkListeners',
                        },
                    })
                );
            }
        }

        // Handles country selector display events
        if (data.countrySelectorEvent && !data.modalUrl) {
            document.dispatchEvent(new Event(data.countrySelectorEvent));
        }

        document.dispatchEvent(
            new CustomEvent('sessionDataUpdated', {
                detail: {
                    data,
                },
            })
        );
    });
};

export const init = () => {
    // SVG sprite loader
    loadSprites();

    const onDomReady = () => {
        initSessionState();
        new HeartBeat();
    };

    loadAfterDOMContentLoaded(onDomReady);

    // triggered to load req'd assets and dependencies when help center loads
    document.addEventListener('HelpCenterLoaded', () => {
        initSessionState();
    });
};

export default init;
