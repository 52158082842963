import { bindEvents } from '../../helpers';

// BITS

import FieldSearch, { selector as FieldSearchSelector } from '../../../bits/field-search/script';

// COMPONENTS

// BITS
import Card, { selector as CardSelector } from '../../../bits/card/script';
import CardFpc, { selector as CardFpcSelector } from '../../../bits/card-fpc';

// MODULES

// accordions
import AccordionPdp, {
    selector as AccordionPdpSelector,
} from '../../../modules/accordions/accordion-pdp';

// anchor links
import AnchorLink, { selector as AnchorLinkSelector } from '../../../modules/anchors/anchor-link';

// columns for impact cards
import ColumnsImpact, { selector as ColumnsImpactSelector } from '../../../modules/columns-impact';

// drawers
import DrawerBottom, {
    selector as DrawerBottomSelector,
} from '../../../modules/drawers/drawer-bottom';
import DrawerTop, {
    selector as DrawerTopSelector,
} from '../../../modules/drawers/drawer-top/script';

// grids
// import GridProductTiles, { selector as GridProductTilesSelector } from '../../../modules/grid/grid-product-tiles/script';
import GridProductTilesPlpCarousel, {
    selector as GridProductTilesPlpCarouselSelector,
} from '../../../modules/grid/grid-product-tiles-plp-carousel';

// heroes
import HeroFpc, { selector as HeroFpcSelector } from '../../../modules/hero/hero-fpc';
import HeroCategory, {
    selector as HeroCategorySelector,
} from '../../../modules/hero/hero-category';
import HeroMain, { selector as HeroMainSelector } from '../../../modules/hero/hero-main';
import HeroMainV2, { selector as HeroMainV2Selector } from '../../../modules/hero/hero-main-v2';

// two column pdp
import PDP2Col, { selector as PDP2ColSelector } from '../../../modules/pdp-2-col';

// mapbox
import MapBox, { selector as MapBoxSelector } from '../../../modules/mapbox/script';

// modals
import ModalFpc, { selector as ModalFpcSelector } from '../../../modules/modals/modal-fpc';
// import ModalGallery, { selector as ModalGallerySelector } from '../../../modules/modals/modal-gallery/script';

// navigation
import NavigationFpc, {
    selector as NavigationFpcSelector,
} from '../../../modules/navigation/navigation-fpc';
import NavigationPrimary, {
    selector as NavigationPrimarySelector,
} from '../../../modules/navigation/navigation-primary/script';
import NavigationSubnavPdp, {
    selector as NavigationSubnavPdpSelector,
} from '../../../modules/navigation/navigation-subnav-pdp';
import NavigationSubnavTabs, {
    selector as NavigationSubnavTabsSelector,
} from '../../../modules/navigation/navigation-subnav-tabs';
import ProductCategoryTabs, {
    selector as ProductCategoryTabsSelector,
} from '../../../bits/product-category-tabs';
import PaneTabs, { selector as PaneTabsSelector } from '../../../bits/pane-tabs';

// scroll mods
import ScrollTakeOver, {
    selector as ScrollTakeOverSelector,
} from '../../../modules/scroll-take-over';

// search overlay
import SearchOverlay, {
    selector as SearchOverlaySelector,
} from '../../../modules/search/search-overlay/script';
import SearchResults, {
    selector as SearchResultsSelector,
} from '../../../modules/search/search-results/script';

// shopping tool
import ShoppingRefinements, {
    selector as ShoppingRefinementsSelector,
} from '../../../modules/shopping-refinements';
import ShoppingTool, { selector as ShoppingToolSelector } from '../../../modules/shopping-tool';
import ShoppingToolSidebar, {
    selector as ShoppingToolSidebarSelector,
} from '../../../modules/shopping-tool-sidebar';

// sliders
import SliderCards, {
    selector as SliderCardsSelector,
} from '../../../modules/sliders/slider-cards';
import SliderCollections, {
    selector as SliderCollectionsSelector,
} from '../../../modules/sliders/slider-collections';
import SliderFeatures, {
    selector as SliderFeaturesSelector,
} from '../../../modules/sliders/slider-features';
import SliderProgressDashboard, {
    selector as SliderProgressDashboardSelector,
} from '../../../modules/sliders/slider-progress-dashboard';
import SliderSocialProof, {
    selector as SliderSocialProofSelector,
} from '../../../modules/sliders/slider-social-proof';
import SliderPageDesigner, {
    selector as SliderPageDesignerSelector,
} from '../../../modules/sliders/slider-page-designer';
import SliderEinstein, {
    selector as SliderEinsteinSelector,
} from '../../../modules/sliders/slider-einstein';

// storytelling modules
import StorytellingScroller, {
    selector as StorytellingScrollerSelector,
} from '../../../modules/storytelling/storytelling-scroller';

import PDInlineEmailSignup, {
    selector as PDInlineEmailSignupSelector,
} from '../../../modules/pd-inline-email-signup';

// main class

class ControllerModule {
    constructor() {
        this.el = {
            target: null,
        };

        this.config = {};

        this.timeout = null;

        this.flags = {
            isInitialized: false,
            isHashUrl: false,
        };

        this.moduleClasses = {};
        this.instantiatedModules = [];

        this.events = bindEvents(this);

        if ('addEventListeners' in this) {
            this.addEventListeners();
        }
    }

    // initializers

    init(target, config = {}) {
        if (!this.flags.isInitialized) {
            this.el.target = target;

            this.moduleClasses = {
                // BITS
                Card: { class: Card, selector: CardSelector },
                CardFpc: { class: CardFpc, selector: CardFpcSelector },

                FieldSearch: {
                    class: FieldSearch,
                    selector: FieldSearchSelector,
                },

                // COMPONENTS

                // accordions
                AccordionPdp: {
                    class: AccordionPdp,
                    selector: AccordionPdpSelector,
                },

                // alerts
                AnchorLink: { class: AnchorLink, selector: AnchorLinkSelector },

                // columns for impact cards
                ColumnsImpact: {
                    class: ColumnsImpact,
                    selector: ColumnsImpactSelector,
                },

                // drawers
                DrawerBottom: {
                    class: DrawerBottom,
                    selector: DrawerBottomSelector,
                },
                DrawerTop: { class: DrawerTop, selector: DrawerTopSelector },

                // grid
                // GridProductTiles: { class: GridProductTiles, selector: GridProductTilesSelector },
                GridProductTilesPlpCarousel: {
                    class: GridProductTilesPlpCarousel,
                    selector: GridProductTilesPlpCarouselSelector,
                },

                // heroes
                HeroFpc: { class: HeroFpc, selector: HeroFpcSelector },
                HeroCategory: { class: HeroCategory, selector: HeroCategorySelector },
                HeroMain: { class: HeroMain, selector: HeroMainSelector },
                HeroMainV2: { class: HeroMainV2, selector: HeroMainV2Selector },

                // two column pdp
                PDP2Col: { class: PDP2Col, selector: PDP2ColSelector },

                // mapbox
                MapBox: { class: MapBox, selector: MapBoxSelector },

                // modals
                ModalFpc: {
                    class: ModalFpc,
                    selector: ModalFpcSelector,
                },

                NavigationFpc: {
                    class: NavigationFpc,
                    selector: NavigationFpcSelector,
                },
                NavigationPrimary: {
                    class: NavigationPrimary,
                    selector: NavigationPrimarySelector,
                },
                NavigationSubnavPdp: {
                    class: NavigationSubnavPdp,
                    selector: NavigationSubnavPdpSelector,
                },
                NavigationSubnavTabs: {
                    class: NavigationSubnavTabs,
                    selector: NavigationSubnavTabsSelector,
                },
                ProductCategoryTabs: {
                    class: ProductCategoryTabs,
                    selector: ProductCategoryTabsSelector,
                },
                PaneTabs: {
                    class: PaneTabs,
                    selector: PaneTabsSelector,
                },

                // scroll graph
                ScrollTakeOver: {
                    class: ScrollTakeOver,
                    selector: ScrollTakeOverSelector,
                },

                // search overlay
                SearchOverlay: {
                    class: SearchOverlay,
                    selector: SearchOverlaySelector,
                },
                SearchResults: {
                    class: SearchResults,
                    selector: SearchResultsSelector,
                },

                // shopping tool
                ShoppingRefinements: {
                    class: ShoppingRefinements,
                    selector: ShoppingRefinementsSelector,
                },
                ShoppingTool: {
                    class: ShoppingTool,
                    selector: ShoppingToolSelector,
                },
                ShoppingToolSidebar: {
                    class: ShoppingToolSidebar,
                    selector: ShoppingToolSidebarSelector,
                },

                // sliders
                SliderCards: {
                    class: SliderCards,
                    selector: SliderCardsSelector,
                },
                SliderCollections: {
                    class: SliderCollections,
                    selector: SliderCollectionsSelector,
                },
                SliderFeatures: {
                    class: SliderFeatures,
                    selector: SliderFeaturesSelector,
                },
                SliderProgressDashboard: {
                    class: SliderProgressDashboard,
                    selector: SliderProgressDashboardSelector,
                },
                SliderSocialProof: {
                    class: SliderSocialProof,
                    selector: SliderSocialProofSelector,
                },
                SliderPageDesigner: {
                    class: SliderPageDesigner,
                    selector: SliderPageDesignerSelector,
                },
                SliderEinstein: {
                    class: SliderEinstein,
                    selector: SliderEinsteinSelector,
                },

                // storytelling scrolling module
                StorytellingScroller: {
                    class: StorytellingScroller,
                    selector: StorytellingScrollerSelector,
                },

                PDInlineEmailSignup: {
                    class: PDInlineEmailSignup,
                    selector: PDInlineEmailSignupSelector,
                },
            };

            this.config = Object.assign(this.config, config);

            this.flags.isHashUrl = !!(
                window.location.hash && document.querySelector(`${window.location.hash}`)
            );

            this.flags.isInitialized = true;

            this.scan();
        }
    }

    // scan for changes

    scan() {
        if (this.flags.isInitialized) {
            // check for previously instantiated modules no longer on dom

            const validInstantiatedModules = [];

            for (let i = 0; i < this.instantiatedModules.length; i += 1) {
                const moduleObject = this.instantiatedModules[i];
                const $module = moduleObject.target;
                if (
                    !$module.closest('body') &&
                    moduleObject.instance &&
                    typeof moduleObject.instance.destroy === 'function'
                ) {
                    moduleObject.instance.destroy();
                } else {
                    validInstantiatedModules.push(moduleObject);
                }
            }

            this.instantiatedModules = validInstantiatedModules;
        }

        // check for new modules to instantiate based on dom selectors
        Object.keys(this.moduleClasses).forEach((key) => {
            const moduleConfig = this.moduleClasses[key];
            const ModuleClass = moduleConfig.class;
            const selector = `[data-${moduleConfig.selector}]`;
            const $modules = this.el.target.querySelectorAll(selector);

            if ($modules.length) {
                for (let i = 0; i < $modules.length; i += 1) {
                    const $module = $modules[i];
                    if (
                        !$module.getAttribute('data-js-module') &&
                        !$module.classList.contains('skeleton') &&
                        !$module.closest('.skeleton')
                    ) {
                        const newModule = new ModuleClass($module);

                        if (newModule instanceof Promise) {
                            newModule.then((instance) => {
                                this.instantiatedModules.push({
                                    target: $module,
                                    instance,
                                    selector,
                                });
                                clearTimeout(this.timeout);
                                this.timeout = setTimeout(this.scrollToHash.bind(this), 500);
                            });
                        } else {
                            this.instantiatedModules.push({
                                target: $module,
                                instance: newModule,
                                selector,
                            });
                            clearTimeout(this.timeout);
                            this.timeout = setTimeout(this.scrollToHash.bind(this), 500);
                        }

                        $module.setAttribute('data-js-module', true);
                    }
                }
            }
        });
    }

    scrollToHash() {
        if (this.flags.isHashUrl && this.flags.isInitialized) {
            // check if anchor link is present in url after module init,
            // otherwise init may interrupt page scroll to anchor

            const targetEl = document
                .querySelector(`${window.location.hash}`)
                .getBoundingClientRect();

            const isInViewport = !!(
                targetEl.top >= 0 &&
                targetEl.left >= 0 &&
                targetEl.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                targetEl.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            if (!isInViewport) {
                window.scrollTo({
                    top: window.pageYOffset + targetEl.top,
                });
            }
        }
    }

    // remove all instantiated modules

    removeAll() {
        for (let i = 0; i < this.instantiatedModules.length; i += 1) {
            const moduleObject = this.instantiatedModules[i];
            if ('destroy' in moduleObject.instance) {
                moduleObject.instance.destroy();
            }
        }
        this.instantiatedModules = [];
    }

    destroy() {
        this.removeAll();
        this.removeEventListeners();
    }
}

export default window?.patagonia.app?.controllerModule || new ControllerModule();
