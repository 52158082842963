// helper methods
import {
    mq,
    bindEvents,
    multiAddEventListener,
    multiRemoveEventListener,
} from '../../assets/helpers';

export const selector = 'mapbox';

export default class MapBox {
    constructor(section) {
        this.el = {
            section,
            map: section.querySelector(`.${selector}__map`),
            listWrapper: section.querySelector(`.${selector}__list-wrapper`),
            list: section.querySelector(`.${selector}__list`),
            listItems: section.querySelectorAll(`.${selector}__list .${selector}__list-item`),
            filterLinks: section.querySelectorAll(`.${selector} .navigation-subnav-tabs .nav-link`),
            filterCount: section.querySelector(`.${selector}__count`),
            activeFrame: section.querySelector(`.${selector}__list-active-frame`),
            mobileTabs: section.querySelector(`.${selector}__mobile-tabs`),
            mobileTabBtns: section.querySelectorAll(`.${selector}__mobile-tabs-btn`),
        };

        this.endpoint = 'https://api.mapbox.com/styles/v1/';
        this.accessToken =
            'pk.eyJ1IjoiaXQtd2ViLWR3YWRtaW4iLCJhIjoiY2szZzVjajdqMGM1bDNibnFoa25saHh4YyJ9.puqkMy2Df3zVHhwCDov7Fg';
        this.map = null;
        // this.mapStyle = 'mapbox://styles/basicdev/cjvn34j404ap01cp8oqepurq5';
        this.mapStyle = 'mapbox://styles/it-web-dwadmin/ck3g99zyg08921dl75q74uehl';

        this.staticMapUrls = [];
        this.filterLocations = [];
        this.markers = [];
        this.locations = [];

        this.events = bindEvents(this);

        this.init();
    }

    init() {
        this.getLocations();
        this.createMap();
        this.addEventListeners();

        if (!mq('large')) {
            import('../../assets/components/xhr').then(({ default: XHR }) => {
                this.createStaticMaps(XHR);
            });
        }
    }

    addEventListeners() {
        window.addEventListener('resize', this.events._onResize);

        this.map.on('load', this.events._onMapLoad);
        multiAddEventListener(this.el.filterLinks, 'click', this.events._onFilterLinkClick);

        if (!mq('large')) {
            this.el.listWrapper.classList.add('is-hidden');
            multiAddEventListener(this.el.mobileTabBtns, 'click', this.events._onMobileTabBtnClick);
        } else {
            multiAddEventListener(this.el.listItems, 'click', this.events._onListItemClick);
        }
    }

    removeEventListeners() {
        window.removeEventListener('resize', this.events._onResize);

        this.map.off('load', this.events._onMapLoad);
        multiRemoveEventListener(this.el.listItems, 'click', this.events._onListItemClick);
        multiRemoveEventListener(this.el.filterLinks, 'click', this.events._onFilterLinkClick);
        multiRemoveEventListener(this.el.listItems, 'click', this.events._onListItemClick);
        multiRemoveEventListener(this.el.mobileTabBtns, 'click', this.events._onMobileTabBtnClick);
    }

    _onResize() {
        this.fitMapToBbox();
    }

    _onMapLoad() {
        this.setFilterCount();
        this.fitMapToBbox();
        this.renderMarkers();
    }

    _onListItemClick(e) {
        const id = e.target.closest('li').getAttribute('data-id');
        let activeMarker;

        this.markers.forEach((marker) => {
            if (marker.id === id) activeMarker = marker;
        });
        this.setActiveMarker(activeMarker);
        this.scrollToListItem(e.target.closest('li'));
    }

    _onFilterLinkClick(e) {
        const type = e.currentTarget.getAttribute('data-filter-type');

        this.resetActiveItems();
        this.fitMapToBbox();
        this.filter(type);
    }

    _onMobileTabBtnClick(e) {
        const wasActive = this.el.mobileTabs.querySelector('.mapbox__mobile-tabs-btn.is-active');
        const viewMode = e.currentTarget.getAttribute('data-view');

        if (wasActive) wasActive.classList.remove('is-active');

        if (viewMode === 'map') {
            e.currentTarget.classList.add('is-active');
            this.el.map.classList.remove('is-hidden');
            this.el.listWrapper.classList.add('is-hidden');
        } else if (viewMode === 'list') {
            e.currentTarget.classList.add('is-active');
            this.el.map.classList.add('is-hidden');
            this.el.listWrapper.classList.remove('is-hidden');
        }
    }

    fitMapToBbox() {
        const bounds = new window.mapboxgl.LngLatBounds();

        this.locations.forEach((loc) => bounds.extend(loc.position));

        this.map.fitBounds(bounds, {
            padding: {
                top: 0,
                bottom: 0,
                left: 100,
                right: 125,
            },
        });
    }

    getLocations() {
        this.el.listItems.forEach((item, index) => {
            const type = item.getAttribute('data-facility-type');
            const id = index.toString();
            const title = item.querySelector('[data-facility-title]').innerHTML;
            const address = item.querySelector('[data-facility-address]').innerHTML;
            const link = item.querySelector('[data-facility-link]').getAttribute('href');
            const cta = item.querySelector('[data-facility-link]').text.trim();
            const lon = parseInt(item.getAttribute('data-facility-lon'), 10);
            const lat = parseInt(item.getAttribute('data-facility-lat'), 10);
            const position = [lon, lat];

            const loc = {
                id,
                type,
                title,
                address,
                position,
                cta,
                link,
            };

            item.setAttribute('data-id', id);
            this.locations.push(loc);
        });
    }

    createMap() {
        window.mapboxgl.accessToken = this.accessToken;

        this.map = new window.mapboxgl.Map({
            container: this.el.map,
            style: this.mapStyle,
        });

        const mapNav = new window.mapboxgl.NavigationControl();

        this.map.scrollZoom.disable();
        this.map.addControl(mapNav, 'bottom-left');
    }

    // set functions

    setFilterCount() {
        this.el.filterCount.innerHTML = `(${this.locations.length})`;
    }

    setActiveListItem(id) {
        this.el.listItems.forEach((item) => {
            if (item.getAttribute('data-id') === id) {
                item.classList.add('is-active');
            } else {
                item.classList.remove('is-active');
            }
        });
    }

    setActiveMarker(el) {
        if (mq('large')) this.setActiveListItem(el.id);

        let zoomLevel;

        if (mq('large')) {
            zoomLevel = 5;
        } else {
            zoomLevel = 2.5;
        }

        this.map.flyTo({
            center: el.getLngLat(),
            zoom: zoomLevel,
        });

        this.markers.forEach((marker) => {
            if (marker.id === el.id) {
                marker._element.classList.add('is-active');
            } else {
                marker._element.classList.remove('is-active');
            }
        });
    }

    resetActiveItems() {
        const currentMarker = this.el.section.querySelector('.mapbox__marker.is-active');
        const currentListing = this.el.section.querySelector('.mapbox__list-item.is-active');

        if (currentMarker) currentMarker.classList.remove('is-active');
        if (currentListing) currentListing.classList.remove('is-active');

        this.el.listWrapper.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    // scroll active list item into view

    scrollToListItem(el) {
        const { offsetTop } = el;
        const listingHeight = el.offsetHeight;
        const listWrapperHeight = this.el.listWrapper.offsetHeight;

        if (
            offsetTop + this.el.listWrapper.scrollTop + listingHeight > listWrapperHeight ||
            offsetTop < this.el.listWrapper.scrollTop
        ) {
            this.el.listWrapper.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    }

    clearMarkers() {
        this.markers.forEach((marker) => marker.remove());
        this.markers = [];
    }

    // render markers for all locations

    renderMarkers() {
        if (this.markers.length > 0) this.clearMarkers();

        this.locations.forEach((loc) => {
            let classModifier = '';

            const types = loc.type.split(',').map((typeString) => typeString.trim());

            types.forEach((type) => {
                // set color class based on facility type
                if (type === 'factory') {
                    classModifier = ' mapbox__marker--factory';
                } else if (type === 'material-supplier') {
                    classModifier = ' mapbox__marker--material-supplier';
                } else if (type === 'farm') {
                    classModifier = ' mapbox__marker--farm';
                }
            });

            // create a DOM element for the marker
            const el = document.createElement('div');

            el.innerHTML =
                '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><circle fill="#FFF" fill-rule="evenodd" r="7" cx="8" cy="8"/></svg>';
            el.classList.add(
                'mapbox__marker',
                ...classModifier.split(' ').filter((string) => string && string.length > 0)
            );

            let marker;

            const typeString = loc.type.replace(/-/g, ' ');

            // create popup only for mobile
            if (!mq('large')) {
                const popup = new window.mapboxgl.Popup({
                    offset: 0,
                    anchor: 'center',
                    className: `mapbox__list-item mapbox__list-item--${loc.type} mapbox__list-item--popup`,
                }).setHTML(`
                    <div class="mapbox__list-item-inner">
                        <div class="mapbox__list-item-eyebrow"><figure></figure><span class="h11">${typeString}</span></div>
                        <h4 class="h8">${loc.title}</h4>
                        <p>${loc.address}</p>
                        <a href="${loc.link}" target="_self" class="cta-link-underline cta-link-underline--h9 cta-link--underlined" data-cta-link-underline>
                            <span class="h9">${loc.cta}</span>
                        </a>
                    </div>
                `);

                marker = new window.mapboxgl.Marker(el)
                    .setLngLat({
                        lon: loc.position[0],
                        lat: loc.position[1],
                    })
                    .setPopup(popup)
                    .addTo(this.map);
            } else {
                marker = new window.mapboxgl.Marker(el)
                    .setLngLat({
                        lon: loc.position[0],
                        lat: loc.position[1],
                    })
                    .addTo(this.map);
            }

            marker.name = loc.name;
            marker.id = loc.id;
            this.markers.push(marker);

            el.addEventListener('click', () => {
                this.el.listItems.forEach((item) => {
                    if (item.dataset.id === marker.id) {
                        this.setActiveMarker(marker);

                        if (mq('large')) {
                            this.setActiveListItem(marker.id);
                            this.scrollToListItem(item);
                        }
                    }
                });
            });
        });
    }

    // create mobile card images using static map api

    createStaticMaps(XHR) {
        const zoom = 5;
        const size = '300x348';
        const style = 'basicdev/cjvn34j404ap01cp8oqepurq5';

        this.locations.forEach((loc) => {
            let hex;

            if (loc.type.includes('factory')) {
                hex = '32B67A';
            } else if (loc.type.includes('material-supplier')) {
                hex = 'FA4616';
            } else if (loc.type.includes('farm')) {
                hex = '003DA5';
            }

            const lon = loc.position[0];
            const lat = loc.position[1];
            const reqUrl = `${this.endpoint}${style}/static/pin-l+${hex}(${lon},${lat})/${lon},${lat},${zoom},0,0/${size}?access_token=${this.accessToken}`;

            new XHR('json').get(reqUrl).then(
                (xhr) => {
                    if (xhr.responseURL) {
                        const imageContainer = this.el.section.querySelector(
                            `.mapbox__list-item[data-id="${loc.id}"]`
                        );
                        const image = document.createElement('img');
                        image.src = xhr.responseURL;
                        imageContainer.insertBefore(image, imageContainer.firstChild);
                    } else {
                        console.warn('Error gettin static map image url', xhr);
                    }
                },
                (xhr) => {
                    console.error('Error gettin static map image url', xhr);
                }
            );
        });
    }

    // filter map markers and listing by type
    filter(type) {
        this.el.section.dataset.mapFilter = type;
    }

    destroy() {
        this.removeEventListeners();
    }
}
