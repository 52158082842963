// helper methods
export const selector = 'hero-main-v2';

export default class HeroMainV2 {
    constructor(section) {
        return import(/* webpackPrefetch: true */ './script').then(
            ({ default: AsyncComponent }) => new AsyncComponent(section)
        );
    }
}
